import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
// import Preview from "../components/preview"
import Previews from "../components/previews"

const IndexPage = ({ data }) => {
  const notes = data.allMarkdownRemark.edges
  // const previews = notes.map((note, i) => <Preview note={note.node} key={i} />)
  return (
    <Layout>
      <Previews notes={notes.map(note => note.node)} />
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query HomePageQuery {
    allMarkdownRemark(limit: 1000) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`
