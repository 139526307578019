import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Previews = ({ notes }) => (
  <ul className="previews">
    {notes.map((note, i) => (
      <li key={i}>
        <Link to={`/note/${note.frontmatter.slug}`}>
          {note.frontmatter.title}
        </Link>
      </li>
    ))}
  </ul>
)

Previews.propTypes = {
  notes: PropTypes.array,
}

export default Previews
